<template>
	<div
	class="coring_dashboard tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]"
	v-if="campaign.status === 'results_obtained' || (campaign.status === 'sent_for_analysis' && campaign.asbestosResultCount && $hasRight('globalActions.accessDashboardNotFinished')) || $hasRight('globalActions.superAdmin')"
	>
		<HeaderSlot
		:toBackButton="{ name: 'project', params: { id: campaign.workspace.id } }"
		:title="$cap(campaign.name)"
		:subTitle="'Gérer la campagne - ' + cores.length + (cores.length < 2 ? ' carotte' : ' carottes')"
		:isBackButtonHidden="false === isGuest"
		>
			<ButtonSlot
			v-if="
				(false === isGuest &&
					(campaign.status === 'sent_for_analysis' ||
						campaign.status === 'results_obtained' ||
						campaign.status === 'status_archived') &&
					$hasRight('campaigns.generateLink')) || $hasRight('users.isSuperAdmin')
			"
			@click="openGenerateLinkModale()"
			>
				Générer un lien public
			</ButtonSlot>

			<EngineerQuestion
			v-if="!isGuest && false === $store.state.user.current.isInternal"
			:_res="1550"
			/>

			<ButtonSlot
			v-if="(campaign.hasAsbestos || campaign.hasPAH || campaign.hasTH) && $hasRight('files.uploadDeliverables')"
			@click="refreshData()"
			:_res="1150"
			_icon="mdi-refresh"
			:_disabled="campaign.isNotRefreshableProvider"
			:_tooltip="campaign.isNotRefreshableProvider ? 'Ce bouton à été désactivé car une action manuelle à été réalisée sur cette campagne. Contactez un administrateur' : ''"
			>
				Rafraîchir les données
			</ButtonSlot>


			<ButtonSlot
			:_to="{
				name: 'campaign-deliverables',
				params: { id: $route.params.id }
			}"
			:_res="1550"
			_icon="mdi-file-check-outline"
			v-if="$hasRight('files.accessDeliverables')"
			>
				Accéder aux livrables
			</ButtonSlot>

			<ButtonSlot
			@click="exportCsv()"
			_icon="mdi-database-export"
			v-if="$hasRight('files.accessDeliverables')"
			>
				Exportation CSV
			</ButtonSlot>

			<ButtonSlot
			:_to="{
				name: mapComponentName,
				params: { id: campaign.id }
			}"
			_icon="mdi-map-outline"
			>
				Accéder à la carte
			</ButtonSlot>
		</HeaderSlot>

		<div class="scrollable_container tw-flex tw-flex-col tw-grow tw-overflow-hidden tw-gap-[10px]">
			<v-textarea
			class="tw-h-[130px] tw-grow-0"
			label="Commentaire global"
			outlined
			min-height="100px"
			color="grey"
			:disabled="!campaign.globalComment && isCommentDisable"
			:clearable="!isCommentDisable"
			:class="[
				isCommentDisable
					? globalCommentInactiveClass
					: globalCommentActiveClass
			]"
			hide-details
			auto-grow
			v-model="campaign.globalComment"
			:readonly="isCommentDisable"
			v-if="$store.state.user.current.isInternal === true || (campaign.globalComment !== null && $store.state.user.current.isInternal === false)"
			>
				<template slot="append">
					<div
					class="edit_button"
					v-if="$hasRight('campaigns.addCommentsToDashboard')"
					>
						<v-icon
						v-if="isCommentDisable"
						@click="enableModifyComment"
						>
							mdi-pen
						</v-icon>

						<v-icon
						v-if="!isCommentDisable"
						@click="modifyComment"
						>
							mdi-check-outline
						</v-icon>
					</div>
				</template>
			</v-textarea>

			<div
			v-if="campaign.status === 'results_obtained' && $hasRight('globalActions.accessDashboardNotFinished')" 
			class="tw-items-center tw-flex tw-flex-row tw-gap-[10px] tw-justify-end tw-w-[100%]"
			>
				Donner l'accès au client ?
				<v-switch
				v-model="campaign.isAccessibleCustomer"
				hide-details
				@click="updateAccessCustomer(campaign.isAccessibleCustomer)"
				/>
			</div>

			<v-tabs
			v-model="tab"
			background-color="transparent"
			color="basil"
			class="tw-grow-0"
			>
				<v-tab
				key="stats"
				v-show="campaign.hasAsbestos || campaign.hasPAH || campaign.hasTH"
				active-class="activeTab"
				>
					Statistique
				</v-tab>

				<v-tab
				key="table"
				v-show="campaign && campaign.coringType === 'CS' && $hasRight('users.isInternal')"
				active-class="activeTab"
				>
					Tableau synoptique 
				</v-tab>

				<v-tab
				key="synth"
				v-show="campaign && $hasRight('users.isInternal')"
				active-class="activeTab"
				>
					Tableau de synthèse 
				</v-tab>
			</v-tabs>

			<v-tabs-items
			v-model="tab"
			class="tw-grow tw-overflow-y-auto tw-py-[10px]"
			>
				<v-tab-item
				key="stats"
				v-show="campaign.hasAsbestos || campaign.hasPAH || campaign.hasTH"
				>
					<h2
					v-if="campaign.status === 'sent_for_analysis' && campaign.asbestosResultCount"
					class="tw-flex tw-justify-center tw-mb-[10px]"
					style="color: red"
					>
						Attention : Résultats partiel
					</h2>
					<v-row
					class="mx-0 justify-center"
					>
						<v-col
						cols="4"
						class="py-2"
						v-show="campaign.hasAsbestos"
						>
							<v-card outlined>
								<v-card-title class="justify-center">
									Répartition de l'amiante par couche d'analyse ({{ countAnalysesByType('ASBESTOS') }} couches expertisées)
								</v-card-title>

								<v-card-text class="justify-center">
									<div
									ref="asbestosPieChart"
									class="graph"
									>
										<v-progress-circular
										class="Loading"
										:size="300"
										:width="10"
										color="#2c0703"
										indeterminate
										/>
									</div>
								</v-card-text>
							</v-card>
						</v-col>

						<v-col
						cols="4"
						class="py-2"
						v-show="campaign.hasPAH"
						>
							<v-card outlined>
								<v-card-title class="justify-center">
									Répartition du HAP par couche d'analyse ({{ countAnalysesByType('PAH') }} couches expertisées)
								</v-card-title>

								<v-card-text class="justify-center">
									<div
									ref="pahPieChart"
									class="graph"
									>
										<v-progress-circular
										class="Loading"
										:size="300"
										:width="10"
										color="#2c0703"
										indeterminate
										/>
									</div>
								</v-card-text>
							</v-card>
						</v-col>

						<v-col
						cols="4"
						class="py-2"
						v-show="campaign.hasTH"
						>
							<v-card outlined>
								<v-card-title class="justify-center">
									Répartition du HCT par couche d'analyse ({{ countAnalysesByType('TH') }} couches expertisées)
								</v-card-title>

								<v-card-text class="justify-center">
									<div
									ref="thPieChart"
									class="graph"
									>
										<v-progress-circular
										class="Loading"
										:size="300"
										:width="10"
										color="#2c0703"
										indeterminate
										/>
									</div>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<div
					class="tw-flex tw-row tw-justify-around tw-mt-[10px] background tw-mx-[12px] tw-rounded-[4px] tw-mt-[24px] tw-p-[20px]"
					>
						<div
						v-if="campaign.hasAsbestos"
						>
							<v-switch
							hide-details
							v-model="asbestosFilter"
							label="Filtrer les couches amiantées"
							/>
						</div>

						<div
						v-if="campaign.hasPAH"
						>
							<v-switch
							hide-details
							v-model="pahFilter"
							label="Filtrer les couches HAP > 500"
							/>
						</div>

						<div
						v-if="campaign.hasTH"
						>
							<v-switch
							hide-details
							v-model="thFilter"
							label="Filtrer les couches HCT"
							/>
						</div>
					</div>

					<AsbestosTable
					v-if="campaign.hasAsbestos"
					class="mt-5 px-3"
					:asbestosTableData="filteredData"
					:campaign="campaign"
					/>

					<PahTable
					v-if="campaign.hasPAH"
					:pahTableData="filteredData"
					class="mt-5 px-3"
					/>

					<ThTable
					:hctTableData="filteredData"
					v-if="campaign.hasTH"
					class="mt-5 px-3"
					/>
				</v-tab-item>

				<v-tab-item
				key="table"
				v-show="campaign && campaign.coringType === 'CS'"
				>
					<StructuralTable
					:campaign="campaign"
					/>
				</v-tab-item>

				<v-tab-item
				key="synth"
				v-show="campaign"
				>
					<SynthesisTable
					:campaign="campaign"
					/>
				</v-tab-item>
			</v-tabs-items>
		</div>

		<Modale
		v-if="isGenerateLinkModaleOpen"
		title="Partager la campagne"
		@close="closeGenerateLinkModale"
		@validate="closeGenerateLinkModale"
		>
			<div class="generateLinkInput">
				<div
				class="justify-center mt-4"
				style="width: 100%"
				>
					<v-row class="pr-4 pl-4">
						<v-text-field
						dense
						ref="generatedLink"
						outlined
						disabled
						:value="url"
						:loading="loadUrlGuest"
						class="mr-4"
						/>

						<MediumButtonSlot @click="copyTextToClipboard()">
							Copier le lien
						</MediumButtonSlot>
					</v-row>
				</div>
			</div>

			<template slot="actions">
				<LowButtonSlot @click="closeGenerateLinkModale()">
					Fermer
				</LowButtonSlot>
			</template>
		</Modale>
	</div>

	<div
	v-else
	class="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-w-full tw-h-full"
	>
		Aucun résultat disponible sur cette campagne pour le moment
		<SiteDeactivated v-if="isSiteDeactivated"/>
	</div>
</template>

<script>
import amchartUtils from "../../utils/amcharts.utils.js";
import AsbestosTable from "../../components/coring/dashboard/Asbestos.table";
import StructuralTable from "../../components/coring/dashboard/Structural.table.vue";
import PahTable from "../../components/coring/dashboard/Hap.table";
import ThTable from "../../components/coring/dashboard/Hct.table";
import areiaUtils from "../../utils/areia.utils";
import eurofinsUtils from "../../utils/eurofins.utils";
import auth from "../../service/auth";
import SiteDeactivated from "../../components/campaign/SiteDeactivated.vue";
import EngineerQuestion from "../../components/popup/EngineerQuestion";
import {mapGetters, mapActions} from "vuex";
import SynthesisTable from "../../components/coring/dashboard/Synthesis.table.vue";

export default {
	name: "CoringCampaignDashboard",
	components: {
		ThTable,
		PahTable,
		AsbestosTable,
		SiteDeactivated,
		EngineerQuestion,
		StructuralTable,
		SynthesisTable
	},
	data(){
		return {
			isGuest: false,
			mapComponentName: "coring-campaign-map",
			url:
        window.location.origin +
        "/share-link?token=eajklsdDZSdgfHsJjfEYhfe4lsdDhdgf5hSEafAlsdDhdgfk",
			isGenerateLinkModaleOpen: false,
			campaign: {
				workspace: {
					id: 1
				},
				globalComment: ""
			},
			tableData: [],
			asbestosAllData: [],
			pahAllData: [],
			thAllData: [],
			thPieChart: [],
			asbestosPieChart: [],
			pahPieChart: [],
			thChart: [],
			asbestosChart: [],
			pahChart: [],
			cores: [],
			asbestosFilter: false,
			thFilter: false,
			pahFilter: false,
			isCommentDisable: true,
			campaignPrestations: [],
			hasPah: false,
			hasTh: false,
			globalCommentActiveClass: "cursor_text",
			globalCommentInactiveClass: "cursor_default",
			composeList: [
				"Naphtalène",
				"Acénaphtylène",
				"Acénaphtène",
				"Fluorène",
				"Phénanthrène",
				"Anthracène",
				"Fluoranthène",
				"Pyrène",
				"Benzo(a)anthracène",
				"Chrysène",
				"Benzo(b)fluoranthène",
				"Benzo(k)fluoranthène",
				"Benzo(a)pyrène",
				"Indéno(1,2,3-cd)pyrène",
				"Dibenzo(a,h)anthracène",
				"Benzo(g,h,i)pérylène"
			],
			loadUrlGuest: true,
			isSiteDeactivated: false,
			tab: "",
		};
	},
	computed: {
		...mapGetters("downloader", ["downloading", "downloadList"]),
		filteredData(){
			let finalData = this.tableData;

			if(true === this.asbestosFilter) try {
				if(this.campaign.provider === "EUROFINS" || this.campaign.provider === "EUROFINS - Spécifique DIR EST"){
					finalData = finalData.filter(
						l => l.results.ASBESTOS?.find(e => e.containsAsbestos === true)
					);
				}
				else {
					finalData = finalData.filter(
						l => l.results.ASBESTOS?.containsAsbestos === true
					);
				}
			}
			catch (e){
				finalData = [];
			}

			if(true === this.pahFilter) try {
				finalData = finalData.filter(l => 500 < l.results.PAH?.sum);
			}
			catch (e){
				finalData = [];
			}

			if(true === this.thFilter) try {
				finalData = finalData.filter(l => 500 < l.results.TH?.sum);
			}
			catch (e){
				finalData = [];
			}

			return finalData;
		}
	},
	watch: {
	},
	methods: {
		...mapActions("downloader", [
			"setDownloading", "setDownloadList", "deleteFromDownloadList", "setDownloadStatus"
		]),
		countAnalysesByType(type){
			let count = 0;
			switch (type){
				case "ASBESTOS":
					count = this.cores.reduce((acc, core) => {
						return acc + core.layersAndInterfaces
						.filter(layer => layer.type === "layer" && layer.isSentAsbestos)
						.length;
					}, 0);
					break;
        
				case "PAH":
					count = this.cores.reduce((acc, core) => {
						return acc + core.layersAndInterfaces
						.filter(layer => layer.type === "layer" && layer.isSentPAH)
						.length;
					}, 0);
					break;
        
				case "TH":
					count = this.cores.reduce((acc, core) => {
						return acc + core.layersAndInterfaces
						.filter(layer => layer.type === "layer" && layer.isSentTH)
						.length;
					}, 0);
					break;
        
				default:
					console.error(`Type ${type} non reconnu.`);
					break;
			}
			return count;
		},
		exportCsv(){
			/*this.$api.documents
			.exportStructuralCsv(this.campaign.id)
			.then(documentContent => {
				const url = window.URL.createObjectURL(new Blob([documentContent]));
				const link = document.createElement("a");
				link.href = url;
				let csvName = this.campaign.name + ".csv";
				csvName = csvName.replaceAll(" ", "_");
				link.setAttribute("download", csvName);
				link.click();
			});

			this.$api.documents
			.exportCsvLayers(this.campaign.id)
			.then(documentContent => {
				const url = window.URL.createObjectURL(new Blob([documentContent]));
				const link = document.createElement("a");
				link.href = url;
				let csvName = this.campaign.name + "_couche.csv";
				csvName = csvName.replaceAll(" ", "_");
				link.setAttribute("download", csvName);
				link.click();
			});

			this.$api.documents
			.exportCsvLayersAsbestos(this.campaign.id)
			.then(documentContent => {
				const url = window.URL.createObjectURL(new Blob([documentContent]));
				const link = document.createElement("a");
				link.href = url;
				let csvName = this.campaign.name + "_amiante.csv";
				csvName = csvName.replaceAll(" ", "_");
				link.setAttribute("download", csvName);
				link.click();
			});*/

			let zipName = this.campaign.name.replaceAll(" ", "_") + "_CSV.zip";
			this.setDownloading(true);
			this.setDownloadList([...this.downloadList, {name: zipName, downloadState: "loading"}]);

			this.$api.documents
			.exportCsvZip(this.campaign.id)
			.then(documentContent => {
				const url = window.URL.createObjectURL(new Blob([documentContent]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", zipName);
				link.click();
				this.setDownloadStatus({downloadState: "finished", element: zipName});
			}).catch(error => {
				console.log(error);
				this.setDownloadStatus({downloadState: "failed", element: zipName});
			});
		},
		async generateShareLink(){
			const guestUser = await this.$api.campaigns.inviteGuestUser(
				this.$route.params.id
			);
			this.url =
        window.location.origin + "/share-link?token=" + guestUser.token;
			this.loadUrlGuest = false;
		},
		async refreshData(){
			await this.$api.coringCampaigns.getFromProvider(this.$route.params.id);
			await this.setCampaign(this.$route.params.id);
		},
		fallbackCopyTextToClipboard(){
			this.$refs.generatedLink.focus();
			this.$refs.generatedLink.select();

			try {
				document.execCommand("copy");
			}
			catch (err){
				console.error("unable to copy", err);
			}
		},
		copyTextToClipboard(){
			if(!navigator.clipboard){
				this.fallbackCopyTextToClipboard();
				return;
			}
			navigator.clipboard.writeText(this.url);
			this.$toast("success", "Lien copié dans le presse papier !");
		},
		openGenerateLinkModale(){
			this.isGenerateLinkModaleOpen = true;
			this.generateShareLink();
		},
		closeGenerateLinkModale(){
			this.isGenerateLinkModaleOpen = false;
			this.loadUrlGuest = true;
		},
		async fetchCampaignPrestations(){
			this.campaignPrestations = await this.$api.campaigns.getPrestations(
				this.$route.params.id
			);
		},
		async fetchCampaign(){
			let response = await this.$api.campaigns.findById(this.$route.params.id);
   
			if(response.status === 401 && response.data.code === 5){
				this.isSiteDeactivated = true;
			}

			this.campaign = response;
		},
		async fetchTableData(){
			this.tableData = await this.$api.campaigns.getData(
				this.$route.params.id,
				"table"
			);
		},
		async fetchData(type){
			try {
				this[type + "PieChart"] = await this.$api.campaigns.getData(
					this.$route.params.id,
					"pie",
					type.toUpperCase()
				);
				this[type + "AllData"] = await this.$api.campaigns.getData(
					this.$route.params.id,
					"table",
					type.toUpperCase()
				);
			}
			catch (e){
				this[`has${this.$cap(type)}`] = false;
			}
		},
		fetchGraphs(){
			if(this.campaign.hasAsbestos) this.fetchData("asbestos");
			if(this.campaign.hasPAH) this.fetchData("pah");
			if(this.campaign.hasTH) this.fetchData("th");
		},
		filterAbsestos(){
			let oldData = this.asbestosChart;

			this.asbestosChart = [];
			this.asbestosChart = oldData.filter(
				layer => layer.results.computed.ASBESTOS.positive
			);
		},
		filterPAH(){
			let oldData = this.pahChart;
			this.pahChart = [];
			let results = [];
			for(let a = 0; a < oldData.length; a++){
				// Pour chacun de mes 12 layers

				results.push(oldData[a].results.raw.RESULTAT_COMPOSE); // Récupération des 16 composés de chaque layers
				loop1: for(let b = 0; b < results.length; b++){
					// Pour chacun des 16 composés de chaque layers
					for(let c = 0; c < this.composeList.length; c++){
						// Pour chaques composés
						let value = 0;
						if(results[a][this.composeList[c]] !== "< 0,50"){
							// Si différent de bon (string)
							if(!results[a][this.composeList[c]].toString().includes(">")){
								// Vérification int ou string
								value = Number(
									results[a][this.composeList[c]].toString().replace(",", ".")
								); // Transformation de value en number
								if(value > 0.5){
									// Deuxième vérification différent de bon (int) = on remet dans le tableau et on stop le for de chaques composés
									this.pahChart.push(oldData[a]);
									break loop1;
								}
							}
							else {
								// Si pas un int, alors c'est un string > 0.50 = On remet dans le tableau et on stop le for de chaques composés
								this.pahChart.push(oldData[a]);
								break loop1;
							}
						}
					}
				}
			}
		},
		filterTH(){
			let oldData = this.thChart;
			this.thChart = [];
			oldData.forEach(e => {
				if(e.results.raw.TOTAUX_C10_C40 > 500){
					this.thChart.push(e);
				}
			});
		},
		filterPahAndTh(){
			this.pahChart = this.pahAllData;
			if(this.pahFilter) this.filterPAH();
			this.pahChart = this.pahChart.filter(e => {
				return (
					this.asbestosChart.filter(ele => ele.coreLayerId === e.coreLayerId)
					.length > 0
				);
			});
			this.thChart = this.thAllData;
			if(this.thFilter) this.filterTH();
			this.thChart = this.thChart.filter(
				e =>
					this.asbestosChart.filter(ele => ele.coreLayerId === e.coreLayerId)
					.length > 0
			);
		},
		showPahGraph(){
			amchartUtils.createPieSimpleChart(
				this.$refs.pahPieChart,
				this.pahPieChart.data,
				this.pahPieChart.title
			);
		},
		showThGraph(){
			amchartUtils.createPieSimpleChart(
				this.$refs.thPieChart,
				this.thPieChart.data,
				this.thPieChart.title
			);
		},
		showAsbestosGraph(){
			amchartUtils.createPieSimpleChart(
				this.$refs.asbestosPieChart,
				this.asbestosPieChart.data,
				this.asbestosPieChart.title
			);
		},
		async fetchCores(){
			this.cores = await this.$api.cores.findByCampaign(this.$route.params.id);
		},
		removeFilters(){
			this.pahFilter = false;
			this.thFilter = false;
			this.asbestosFilter = false;
		},
		enableModifyComment(){
			this.isCommentDisable = false;
		},
		async modifyComment(){
			await this.$api.campaigns.editComment(
				this.$route.params.id,
				this.campaign.globalComment
			);
			this.isCommentDisable = true;
		},
		displayTableIfNoGraph(){
			if(this.campaign && this.campaign.coringType === "CS" && !this.campaign.hasAsbestos && !this.campaign.hasPAH && !this.campaign.hasTH){
				this.tab = 1;
			}
		},
		updateAccessCustomer(data){
			this.$api.coringCampaigns.updateAccessCustomer(this.campaign.id, data);
		}
	},
	created(){
		this.asbestosPieChartUnwatch = this.$watch(
			"asbestosPieChart",
			() => {
				this.showAsbestosGraph();
				this.asbestosPieChartUnwatch();
			},
			{
				deep: true
			}
		);
		this.pahPieChartUnwatch = this.$watch(
			"pahPieChart",
			() => {
				this.showPahGraph();
				this.pahPieChartUnwatch();
			},
			{
				deep: true
			}
		);
		this.thPieChartUnwatch = this.$watch(
			"thPieChart",
			() => {
				this.showThGraph();
				this.thPieChartUnwatch();
			},
			{
				deep: true
			}
		);
	},
	async mounted(){
		this.isGuest = auth.isLoggedGuest();
		this.mapComponentName =
      this.isGuest && !auth.isLogged()
      	? "coring-campaign-map-guest"
      	: "coring-campaign-map";

		amchartUtils.disposeAll();
		await this.fetchCampaign();
		this.fetchCores();
		if(this.campaign.status === "results_obtained" || this.campaign.provider !== null || (this.campaign.status === "sent_for_analysis" && this.campaign.asbestosResultCount)){
			this.fetchTableData();
			await this.fetchCampaignPrestations();
			this.fetchGraphs();
			this.displayTableIfNoGraph();
		}
	}
};
</script>

<style lang="scss" scoped>
.coring_dashboard {
  .graph {
    min-height: 300px;
  }

  .Loading {
    margin-left: 60px;
  }
  .filters {
    .v-card {
      width: 100%;
    }
  }
  .edit_button {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
  .cursor_text {
    textarea {
      cursor: text;
    }
  }
  .cursor_default {
    textarea {
      cursor: default !important;
    }
  }
  .generateLinkInput {
    display: flex;
    align-items: center;
    justify-content: space-around;

    & input {
      width: 100%;
      border: 1px solid gray;
      padding: 5px;
      border-radius: 5px;
    }
  }
  .background {
	background-color: lightgray;
  }
  
}

.v-input--selection-controls {
	margin-top: 0px !important;
	padding-top: 0px !important;
}

.activeTab {
  background-color: #2b080344;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid black;
}

.reverse-switch .v-input__slot {
  flex-direction: row-reverse;
  justify-content: space-around;
  margin-right: 10px !important;

  .v-input--selection-controls__input {
    position: relative;
    right: 0px;
  }
}

</style>
