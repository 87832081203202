import axiosPlugin from "../plugins/axios";
import toastPlugin from "../plugins/messages";
import router from "../router";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findById = async id => {
	try {
		const response = await axios.get(`/workspaces/${id}`);

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer le projet" + getMessage(error.response.status)
		);
		throw error;
	}
};

const findByOrganizations = async id => {
	try {
		const response = await axios.get(`/organizations/${id}/workspaces`);

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer le projet" + getMessage(error.response.status)
		);
		throw error;
	}
};

const findByUser = async(
	userId, 
	archived = false,
	limit = false,
	offset = false,
	workspaceName = false,
	campaignName = false,
	campaignType = false,
	organization = false,
	site = false,
	reference = false
) => {
	try {
		let url = `/users/${userId}/workspaces?displayCampaigns=true`;
		url += limit ? `&limit=${limit}` : "";
		url += offset ? `&offset=${offset}` : "";
		url += workspaceName ? `&workspaceName=${encodeURIComponent(workspaceName)}` : "";
		url += campaignName ? `&campaignName=${encodeURIComponent(campaignName)}` : "";
		url += campaignType ? `&campaignType=${campaignType}` : "";
		url += organization ? `&organization=${organization}` : "";
		url += site ? `&site=${site}` : "";
		url += `&archived=${archived}`;
		url += reference ? `&reference=${reference}` : "";

		const response = await axios.get(url);

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les projets" + getMessage(error.response.status)
		);
		throw error;
	}
};

const editArchived = async(workspaceId, isArchived) => {
	const statusText = isArchived ? "archiver" : "désarchiver";
	try {
		const response = await axios.patch(`/workspaces/${workspaceId}`, {
			isArchived
		});

		toast("success", "Projet " + statusText);

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Le projet n'a pas pu être " +
        statusText +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const edit = async(id, workspace) => {
	try {
		const response = await axios.put(`/workspaces/${id}`, workspace);

		toast("success", "Projet modifié");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier le projet: " +
        router.app.$t(error.response.data.message.split("ERROR:")[1].trim())
		);
		throw error;
	}
};

const create = async workspace => {
	try {
		const response = await axios.post("/workspaces", workspace);

		toast("success", "Projet créé");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de créer le projet : " +
        router.app.$t(error.response.data.message.split("ERROR:")[1].trim())
		);
		throw error;
	}
};

const deleteWorkspace = async workspaceId => {
	try {
		const response = await axios.delete(`/workspaces/${workspaceId}`);
	
		toast("success", "Projet supprimé");
	
		return response.data;
	  }
	catch (error){
		toast(
		  "error",
		  "Impossible de supprimer le projet " + getMessage(error.response)
		);
		throw error;
	  }
};

const campaignsInfos = async(
	workspaceId,
	campaignName = false,
	campaignType = false,
	reference = false,
	status = false
) => {
	try {
		let url = `/workspaces/${workspaceId}/campaignsInfos`;
		if(campaignName || campaignType || reference) url += "?";
		campaignName ? url += "&campaignName=" + campaignName : "";
		campaignType ? url += "&campaignType=" + campaignType : "";
		reference ? url += "&reference=" + reference : "";
		status ? url += "&status=" + status : "";
		let response = await axios.get(url);
	
		return response.data;
	  }
	catch (error){
		toast(
		  "error",
		  "Impossible de récupérer le nombre de campagne" + getMessage(error.response)
		);
		throw error;
	  }
};

const workspacesInfos = async(
	userId, 
	archived = false,	
	workspaceName = false,
	campaignName = false,
	campaignType = false,
	organization = false,
	site = false,
	reference = false
) => {
	try {

		let url = `/users/${userId}/workspacesCount`;
		url += `?archived=${archived}`;
		url += workspaceName ? `&workspaceName=${workspaceName}` : "";
		url += campaignName ? `&campaignName=${campaignName}` : "";
		url += campaignType ? `&campaignType=${campaignType}` : "";
		url += organization ? `&organization=${organization}` : "";
		url += site ? `&site=${site}` : "";
		url += reference ? `&reference=${reference}` : "";
		const response = await axios.get(url);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer le nombre de projets" + getMessage(error.response)
		  );
		  throw error;
	}
};

export default {
	findById,
	findByUser,
	editArchived,
	create,
	edit,
	deleteWorkspace,
	findByOrganizations,
	campaignsInfos,
	workspacesInfos
};
