<template>
	<div class="tw-h-full tw-flex tw-flex-col">
		<SendCoringCampaignToProviderValidationPopin
		v-if="campaign.status === 'taking_validated'"
		:site="campaign.workspace.site"
		:open="isCoringCampaignSendDataPopinDisplay"
		v-show="isCoringCampaignSendDataPopinDisplay"
		@closePopin="isCoringCampaignSendDataPopinDisplay = false"
		:campaign="campaign"
		:needButton="false"
		:provider="selectedProvider[0]"
		:AsbestosPrestationsCount="getAsbestosCount()"
		:PAHPrestationsCount="getPAHCount()"
		:THPrestationsCount="getTHCount()"
		:hasGroupement="getHasGroupement()"
		/>

		<CoringFilterPrestationVue
		:cores="cores"
		v-show="displayFilterPrestations"
		@close="displayFilterPrestations = false"
		style="z-index: 10"
		@filterUpdated="updateFilters"
		/>

		<HeaderSlot
		title="Paramétrer les prestations"
		:subTitle="campaign.name + ' - Prestations Amiante : ' + getAsbestosCount() + ' - Prestations HAP : ' + getPAHCount() + ' - Prestations HCT : ' + getTHCount()"
		class="tw-mb-[10px] tw-pt-[24px] tw-px-[24px]"
		@clickBackButton="$router.push('/coring-campaign/' + campaign.id)"
		>
			<v-checkbox
			v-if="isAtLeastOneCoreCancelled"
			label="Afficher les carottes annulées"
			v-model="showCancelledCore"
			hide-details
			/>

			<!--<v-checkbox
			v-if="isAtLeastOneCoreSent"
			label="Afficher les carottes envoyées"
			v-model="showAllCore"
			hide-details
			/>-->

			<ButtonSlot
			_icon="mdi-filter-outline"
			@click="displayFilterPrestations = !displayFilterPrestations"
			/>

			<MediumButtonSlot @click="removeAll()">
				Supprimer tout
			</MediumButtonSlot>

			<ButtonSlot
			@click="allLayerHasPrestation() === true ? saveChanges() : displayModal = true;"
			:_disabled="isValidable === false"
			>
				Valider les prestations
			</ButtonSlot>

			<ButtonSlot
			@click="isCoringCampaignSendDataPopinDisplay = true"
			:_disabled="isValidated === false"
			>
				Envoyer au laboratoire
			</ButtonSlot>
		</HeaderSlot>

		<DragCol
		@isDragging="map.resize()"
		@dragging="map.resize()"
		@dragend="map.resize()"
		>
			<template #right>
				<div
				class="cores-container"
				id="cores-container"
				@mousewheel="handleScroll"
				>
					<CorePrestation
					:ref="'Prestation_' + core.id"
					v-for="(core, index) in cores"
					:key="core.id"
					v-model="cores[index]"
					:prestations="selectedPrestations"
					:provider="selectedProvider[0]"
					:layerPrestations="tempPrestations"
					:campaignStatus="campaign.status"
					@editGroupement="() => {isValidable = true; isValidated = false}"
					:show="isDisplayCore(core)"
					/>
				</div>
			</template>

			<template #left>
				<DragRow
				@isDragging="map.resize()"
				@dragging="map.resize()"
				@dragend="map.resize()"
				>
					<template
					#top
					class="height100"
					>
						<div class="tw-flex tw-justify-between pa-4">
							<div
							v-if="step == 2"
							class="tw-flex tw-flex-row tw-gap-[5px] tw-ml-[12px]"
							>
								<MediumButtonSlot
								@click="
									step = 1;
									selectedPrestations = [];
								"
								>
									Retour au prestataire
								</MediumButtonSlot>

								<ButtonSlot
								:_popup="{
									title: 'Confirmer l\'action',
									subTitle:
										'Êtes-vous sûr de vouloir appliquer les prestations sélectionnées aux couches de toutes les carottes?',
								}"
								_tooltip="Appliquer les prestations sélectionnées aux couches des carottes"
								:_disabled="applyPrestationsDisabled"
								@click="applyPrestationsToAllLayers()"
								>
									Appliquer à toutes les couches
								</ButtonSlot>
							</div>
						</div>

						<div class="left-bottom-container">
							<div class="prestations-container">
								<v-stepper
								elevation="0"
								v-model="step"
								>
									<v-stepper-items>
										<v-stepper-content step="1">
											<v-container fluid>
												<v-data-table
												hide-default-footer
												v-model="selectedProvider"
												:items="providers"
												:headers="providerHeaders"
												:singleSelect="true"
												show-select
												item-key="id"
												class="outlined"
												:loading="providers.length === 0"
												:footer-props="{
													'disable-items-per-page': true
												}"
												@click:row="(item, row) => firstSelectedProvider === false ? row.select(true) : openWarningDialog(row.select, $event)"
												disable-pagination
												>
													<template
													v-slot:item.data-table-select="{
														isSelected,
														select
													}"
													>
														<v-simple-checkbox
														:ripple="false"
														:value="isSelected"
														@input="firstSelectedProvider === false ? select($event) : openWarningDialog(select, $event)"
														off-icon="mdi-radiobox-blank"
														on-icon="mdi-radiobox-marked"
														color="black"
														/>
													</template>
												</v-data-table>
											</v-container>

											<v-card-actions>
												<v-spacer/>

												<HighButtonSlot
												:_disabled="null === selectedProvider[0]"
												class="right_btn"
												@click="step = 2; firstSelectedProvider = selectedProvider[0].id"
												>
													Continuer
												</HighButtonSlot>
											</v-card-actions>
										</v-stepper-content>

										<v-stepper-content step="2">
											<v-container>
												<v-alert
												border="left"
												color="indigo"
												dark
												v-if="mandatoryPrestations"
												>
													Attention, au moins une des prestations marquée
													<v-icon>mdi-alert-decagram-outline</v-icon> doit être
													selectionnée.
												</v-alert>

												<v-data-table
												v-model="selectedPrestations"
												:items="mappedPrestations"
												:headers="prestationHeaders"
												hide-default-footer
												show-select
												item-key="id"
												class="outlined no-data-table-stripes"
												:item-class="getRowClass"
												:loading="prestations.length === 0"
												:footer-props="{
													'disable-items-per-page': true
												}"
												@click:row="
													(item, row) => row.select(!row.isSelected)
												"
												disable-pagination
												>
													<template v-slot:item.code="{ item }">
														{{ item.code }}

														<v-tooltip
														bottom
														v-if="item.isMandatory === true"
														>
															<template v-slot:activator="{ on, attrs }">
																<v-icon
																v-bind="attrs"
																v-on="on"
																>
																	mdi-alert-decagram-outline
																</v-icon>
															</template>

															<span>Prestation obligatoire</span>
														</v-tooltip>
													</template>
												</v-data-table>
											</v-container>
										</v-stepper-content>
									</v-stepper-items>
								</v-stepper>
							</div>
						</div>
					</template>

					<template #bottom>
						<div class="bottom-containers">
							<CoringMap 
							ref="coringMap"
							noWidth="true"
							class="tw-h-full tw-w-full"
							/>
						</div>
					</template>
				</DragRow>
			</template>
		</DragCol>

		<div
		v-show="isHelpShown"
		class="helpContainer"
		>
			<h2>Notice d'utilisation</h2>

			<p>
				Cette vue est un prototype permettant le paramétrage des prestations
				Amiante, HAP & HCT au couche par couche. <br/>
				Il est fort probable que des changements graphiques et fonctionnels
				arrivent régulièrement.
			</p>

			<ul>
				<li>
					Pour ajouter une/des prestation(s) sur une couche, sélectionner le
					prestataire et cliquer sur le nom de la couche
				</li>

				<li>
					Pour ajouter une/des prestation(s) sur toutes les couches de la
					carotte, cliquer sur le nom de la carotte
				</li>

				<li>
					Pour retirer une prestation, effectuer un double clic sur la
					prestation
				</li>

				<li>
					Pour changer la priorité d'une carotte, cliquer sur STD / RUSH
					(Eurofins uniquement)
				</li>
			</ul>

			<LowButtonSlot @click="isHelpShown = false">
				fermer
			</LowButtonSlot>
		</div>

		<div
		class="modal"
		v-if="displayModal"
		>
			<v-card
			flat
			outlined
			>
				<h3 class="tw-pb-[24px]">
					Validation des prestations
				</h3>

				<p>Êtes-vous sûr de vouloir valider ? Certaines couches ne comportent pas de prestation !</p>

				<div class="tw-col-span-12 tw-flex">
					<div class="tw-flex container-button tw-w-full">
						<ButtonSlot
						_theme="light-gray"
						class="button"
						@click="displayModal = false"
						>
							Annuler
						</ButtonSlot>

						<ButtonSlot
						class="button"
						@click="saveChanges(); displayModal = false"
						>
							Valider
						</ButtonSlot>
					</div>
				</div>
			</v-card>
		</div>

		<div
		class="modal"
		v-if="warningDialog"
		>
			<v-card
			flat
			outlined
			>
				<h3 class="tw-pb-[24px]">
					Changement de prestataire
				</h3>

				<p>Attention, si vous changez de prestataire, toutes les prestations sélectionnées seront supprimées, ainsi que les groupements. Êtes-vous certains de vouloir effectuer cette action ?</p>

				<div class="tw-col-span-12 tw-flex">
					<div class="tw-flex container-button tw-w-full">
						<ButtonSlot
						_theme="light-gray"
						class="button"
						@click="warningDialog = false"
						>
							Annuler
						</ButtonSlot>

						<ButtonSlot
						class="button"
						@click="changeProvider(); warningDialog = false"
						>
							Valider
						</ButtonSlot>
					</div>
				</div>
			</v-card>
		</div>
	</div>
</template>

<script>
import CoringMap from "../../components/coring/Coring.Map.vue";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as turf from "@turf/turf";
import CorePrestation from "@/components/providers/Core.prestations";
import SendCoringCampaignToProviderValidationPopin from "@/components/providers/sendCoringCampaignToProviderValidation.popin.vue";
import {DragCol, DragRow} from "vue-resizer";
import {mapActions, mapGetters} from "vuex";
import CoringFilterPrestationVue from "../../components/coring/Coring.filter.prestation.vue";

export default {
	components: {
		CorePrestation,
		DragCol,
		DragRow,
		SendCoringCampaignToProviderValidationPopin,
		CoringMap,
		CoringFilterPrestationVue
	},
	data(){
		return {
			accessToken:
        "pk.eyJ1IjoiYWJhaWxseTUiLCJhIjoiY2t2dGd1ZXFoMHJhODJubHl2OG5ueGo2byJ9.KC7fg_kMFLW-FMsgoU7UPQ",
			step: 1,
			regExIsTime: new RegExp("^.*(H|J)$"),
			providers: [],
			prestations: [],
			providerHeaders: [
				{
					text: "Choix des prestataires",
					align: "start",
					sortable: true,
					value: "name"
				}
			],
			prestationHeaders: [
				{
					text: "Code",
					sortable: true,
					value: "code"
				},
				{
					text: "Prestation",
					sortable: true,
					value: "nameShort"
				},
				{
					text: "Temps estimé",
					sortable: false,
					value: "estimatedDuration"
				}
			],
			selectedProvider: [null],
			selectedPrestations: [],
			mandatoryPrestations: false,
			campaign: {},
			cores: [],
			markers: [],
			isHelpShown: false,
			tempPrestations: null,
			displayModal: false,
			isValidated: false,
			isCoringCampaignSendDataPopinDisplay: false,
			isValidable: false,
			firstSelectedProvider: false,
			warningDialog: false,
			hasAsbestos: false,
			hasPAH: false,
			hasTH: false,
			select: false,
			event: false,
			showAllCore: false,
			showCancelledCore: false,
			displayFilterPrestations: false,
			filters: {}
		};
	},
	computed: {
		...mapGetters("coring", ["map"]),
		applyPrestationsDisabled(){
			if(this.mandatoryPrestations === true){
				let mandatoryPrestationSelected = true;

				this.selectedPrestations.forEach(prestation => {
					if(prestation.isMandatory === true){
						mandatoryPrestationSelected = false;
					}
				});

				return mandatoryPrestationSelected;
			}

			return this.selectedPrestations.length === 0;
		},
		allCount(){
			return this.cores.length;
		},
		mappedSelectedPrestations(){
			return this.mappedPrestations.filter(
				presta =>
					-1 !== this.selectedPrestations.findIndex(p => p.id === presta.id)
			);
		},
		mappedPrestations(){
			let mappedPresta = this.prestations.map(el => {
				let nameSplitted = el.name.split(" ");
				let timeParts = nameSplitted.filter(el => this.regExIsTime.test(el));
				if(timeParts.length >= 1){
					el.estimatedDuration = timeParts[0];
					el.nameShort = nameSplitted
					.filter(el => !this.regExIsTime.test(el))
					.join(" ");
				}
				else {
					el.nameShort = el.name;
					el.estimatedDuration = "/";
				}
				return el;
			});

			mappedPresta.sort((a, b) => {
				if(a.type < b.type) return -1;
				if(a.type > b.type) return 1;

				let aCode = isNaN(a.code) ? a.code : Number(a.code);
				let bCode = isNaN(b.code) ? b.code : Number(b.code);

				if(aCode < bCode) return -1;
				if(aCode > bCode) return 1;

				return 0;
			});

			return mappedPresta;
		},
		isAtLeastOneCoreSent(){
			return this.cores.filter(e => e.isSent === true).length > 0;
		},
		isAtLeastOneCoreCancelled(){
			return this.cores.filter(e => e.status == "cancelled").length > 0;
		}
		
	},
	watch: {
		selectedProvider(){
			if(this.selectedProvider.length !== 0) this.getPrestations(this.selectedProvider[0].id);
		},
		cores(){
			let count = 0;
			if(this.cores.length !== 0){
				this.isValidated = false;
				this.cores.forEach(core => {
					if(core.status === "validated" || core.status === "sent_to_provider"){
						if(core.layers !== undefined){
							core.layers.forEach(layer => {
								if(layer.prestations.length !== 0){
									this.isValidable = true;
									count++;
								}
							});
						}
						else {
							core.layersAndInterfaces.forEach(layer => {
								if(layer.type === "layer"){
									if(layer.layerPrestations.length !== 0){
										this.isValidable = true;
										count++;
									}
								}
							});
						}
					}
				});
			}
			if(count === 0){
				this.isValidable = false;
			}
		}
	},
	methods: {
		...mapActions("coring", [
			"initStore", "purgeStore", "setCampaignStatus", "updateCores", "setCampaign"
		]),
		getAsbestosCount(){
			return this.getPrestationsByType("ASBESTOS");
		},
		getPAHCount(){
			return this.getPrestationsByType("PAH");
		},
		getTHCount(){
			return this.getPrestationsByType("TH");
		},

		getPrestationsByType(type){
			let layers = this.cores
			.filter(c => c.status !== "cancelled" && c.layers.length !== 0)
			.map(c => {
				if(c.layers){
					return c.layers.map(l => ({
						layerId: l.id,
						prestationsIds: l.prestations.map(p => p.prestation.id),
					}));
				}
			})
			.flat(2)
			.filter(layer => layer != undefined);

			let prestationCount = 0;

			layers.forEach(layer => {
				layer.prestationsIds.forEach(id => {
					const prestation = this.prestations.find(e => e.id === id);
					if(prestation && prestation.type === type){
						prestationCount++;
					}
				});
			});

			return prestationCount;
		},
		getHasGroupement(){
			if(this.cores !== undefined){
				let hasGroupement = false;
				this.cores.forEach(core => {

					if(core.layers !== undefined){
						core.layers.forEach(layer => {
							if(layer.layerGroupement){
								hasGroupement = true;
							}
						});
					}
				});
				return hasGroupement;
			}
		},
		updateFilters(filters){
			this.filters = filters;
		},
		openWarningDialog(select, event){
			this.select = select;
			this.event = event;
			this.warningDialog = true;
		},
		allLayerHasPrestation(){
			let allLayerHasPrestation = true;
			this.cores.forEach(core => {
				if(core.status === "validated"){
					if(core.layers !== undefined){
						core.layers.forEach(layer => {
							if(layer.prestations.length !== 0){
								this.isValidable = true;
							}
						});
					}
					else {
						core.layersAndInterfaces.forEach(layer => {
							if(layer.type === "layer"){
								if(layer.layerPrestations.length !== 0){
									this.isValidable = true;
								}
							}
						});
					}
				}
			});
			return allLayerHasPrestation;
		},
		checkIfValidatedStart(){
			this.cores.forEach(core => {
				if(core.layersAndInterfaces.length !== 0){
					core.layersAndInterfaces.forEach(layer => {
						if(layer.type === "layer"){
							if(layer.layerPrestations.length !== 0){
								this.isValidated = true;
								this.isValidable = true;
							}
						}
					});
				}
			});
		},
		applyPrestationsToAllLayers(){
			this.$root.$emit("addPrestations");
		},
		goBack(){
			this.$router.back();
		},
		removeAll(){
			this.cores.forEach(c => {
				c.layers.forEach(l => {
					if(false === l.isSentAsbestos || null === l.isSentAsbestos){
						let index = l.prestations.findIndex(prestation => prestation.type === "ASBESTOS");
						l.prestations.splice(index, 1);
					}
					if(false === l.isSentPAH || null === l.isSentPAH){
						let index = l.prestations.findIndex(prestation => prestation.type === "PAH");
						l.prestations.splice(index, 1);
						this.$refs["Prestation_" + c.id][0].resetGroupement();
					}
					if(false === l.isSentTH || null === l.isSentTH){
						let index = l.prestations.findIndex(prestation => prestation.type === "TH");
						l.prestations.splice(index, 1);
					}
				});
			});
			this.isValidable = true;
		},
		saveChanges(){
			//this.saveCore();
			this.savePrestations();
		},
		saveCore(){
			this.cores.forEach(core => {
				this.$api.cores.edit(core.id, {...core, roadId: core.road.id});
			});
		},
		savePrestations(){
			let layers = this.cores.filter(c => c.status !== "cancelled" && c.layers.length !== 0).map(c => {
				if(undefined !== c.layers){
					return c.layers.map(l => {
						return {
							layerId: l.id,
							prestationsIds: l.prestations.map(p => p.prestation.id),
							groupementNumber: l.layerGroupement
						};
					});
				}
			});

			layers.forEach(layer => {
				layer[0].prestationsIds.forEach(id => {
					let prestation = this.prestations.find(e => e.id === id);
					if(undefined !== prestation){
						if(prestation.type === "ASBESTOS"){
							this.hasAsbestos = true;
						}
						else if(prestation.type === "PAH"){
							this.hasPAH = true;
						}
						else if(prestation.type === "TH"){
							this.hasTH = true;
						}
					}
				});
			});

			this.$api.campaigns.editPrestationsDetail(
				this.$route.params.id,
				layers.flat(2).filter(layer => layer != undefined)
			).then(() => {
				this.isValidated = true;
				this.isValidable = false;
			
				this.$api.coringCampaigns.addPrestationsAndProvider(this.$route.params.id, {
					hasAsbestos: this.hasAsbestos, hasPAH: this.hasPAH, hasTH: this.hasTH, provider: this.selectedProvider[0].name
				});
				//this.fetchCores();
			});
		},
		async fetchCores(){
			this.cores = await this.$api.cores.findByCampaign(this.$route.params.id, false, true);
			this.cores = this.cores.filter(e => e.status === "validated" || e.status === "sent_to_provider" || e.status === "results_acquired" || e.status === "cancelled");
		},
		unselect(id){
			const index = this.selectedPrestations.findIndex(p => p.id === id);
			if(-1 === index) return;

			this.selectedPrestations.splice(index, 1);
		},
		async getCampaign(){
			this.campaign = await this.$api.campaigns.findById(this.$route.params.id);
		},
		async getProviders(){
			const providers = await this.$api.sites.getSiteProviders(
				this.campaign.workspace.site.id
			);
			this.providers = providers.map(ap => ap.provider);

			if(0 === this.providers.length){
				this.$router.push("/admin/organizations/" + this.site.organization.id);
				this.$toast(
					"warning",
					`L'agence ${this.site.name} nécessite un prestataire pour l'envoi des données`
				);
			}
		},
		async getPrestations(id){
			this.prestations = await this.$api.prestations.findByProvider(id);
			let foundMandatoryPrestation = false;

			this.prestations.forEach(prestation => {
				if(prestation.isMandatory === true){
					this.mandatoryPrestations = true;
					foundMandatoryPrestation = true;
				}

				if(foundMandatoryPrestation === false){
					this.mandatoryPrestations = false;
				}
			});
		},
		async validatePrestations(){
			await this.$api.campaigns.editPrestations(
				this.campaign,
				this.selectedPrestations.map(p => p.id)
			);

			this.$toast("success", "Prestations modifiées");
			this.$emit("close");
		},
		handleScroll(event){
			// Empêcher le défilement par défaut
			event.preventDefault();
		
			// Calculer et ajuster la position de défilement vertical du div parent
			const scrollContainer = document.getElementById("cores-container");
			const delta = event.deltaY; // Direction de la molette
			scrollContainer.scrollLeft += delta;
		},
		changeProvider(){
			this.select(this.event);
			this.removeAll();
		},
		isDisplayCore(core){

			const passesFilters = Object.entries(this.filters).every(([key, values]) => {
				// Si le filtre est un tableau (comme pour 'lane'), vérifier que le core a une valeur correspondante
				if(Array.isArray(values) && values.length > 0){
					return values.includes(core[key]);
				}
				// Ajoutez d'autres conditions pour différents types de filtres si nécessaire
				return true;
			});

			return (
				passesFilters &&
       			(
       				(this.showCancelledCore && core.status === "cancelled") ||
					(core.status !== "cancelled")
       			)
			);
		},
		getRowClass(item){
			switch (item.type){
				case "ASBESTOS":
					return "row-type-asbestos";
				case "PAH":
					return "row-type-pah";
				case "TH":
					return "row-type-th";
				default:
					return "";
			}
		},
	},
	async created(){
		await this.initStore({id: this.$route.params.id});
		if(this.selectedPrestations.length > 0){
			this.selectedProvider = [this.selectedPrestations[0].provider];
			this.step = 2;
		}
		await this.getCampaign();
		await this.fetchCores();
		this.checkIfValidatedStart();
		await this.getProviders();
		if(false !== this.campaign.provider && null !== this.campaign.provider){
			let findProvider = this.providers.find(e => e.name === this.campaign.provider);
			this.selectedProvider = [findProvider];
			this.firstSelectedProvider = findProvider;
			this.step = 2;
		}
	}
};
</script>

<style lang="scss">
@import "@/assets/scss/_extends.scss";
.row-type-asbestos {
  background-color: #FF000055 !important;
}

.row-type-asbestos:hover {
  background-color: #FF000055 !important;
}

.row-type-pah {
  background-color: #BBDBB4 !important;
}

.row-type-pah:hover {
  background-color: #BBDBB4 !important;
}

.row-type-th {
  background-color: #fc9635 !important;
}

.row-type-th:hover {
  background-color: #fc9635 !important;
}


tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.outlined {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.slider_col {
  background: #2b0803 !important;
  width: 12px !important;
  margin-right: -2px !important;
  margin-top: 15px !important;
}
.slider_col::before {
  background: white !important;
}
.slider_col::after {
  background: white !important;
}
.slider_row {
  background: #2b0803 !important;
  height: 12px !important;
}
.slider_row::before {
  background: white !important;
}
.slider_row::after {
  background: white !important;
}
.drager_col {
  width: 100% !important;
  height: 100% !important;
}
.drager_row {
  width: 100% !important;
  height: 100% !important;
  margin-bottom: -8px;
}
.drager_right {
  padding: 0px !important;
}
.height100 {
  height: 100%;
}
.cores-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  padding: 10px;
  overflow: auto;
}

.bottom-containers {
  display: flex;
  align-items: flex-start;
  height: 100%;

  .prestations-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .map-container {
    height: 100%;
    width: 100%;
  }
}
.left-bottom-container {
  display: flex;
  align-items: flex-start;
  height: calc(100% - 80px);
  .prestations-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
	padding-left: 16px !important;
  }
}
.selectedPrestas {
  display: flex;
  flex-wrap: wrap;

  .removePrestaIcon {
    &:hover {
      color: red;
    }
  }

  .singlePresta {
    margin: 5px;
  }
}

.recapCard {
  width: auto;

  p {
    text-align: left;
    margin: 0;
  }
}
.helpContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #eee;

  height: 500px;
  width: 500px;

  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  z-index: 100;
}
.pagination {
  z-index: 1;
  position: relative;
  bottom: 50px;
}
.modal {
	width: calc(100vw + 2px);
	height: calc(100vh + 2px);
	background: rgba(0, 0, 0, 0.75);
	position: fixed;
	top: -1px;
	left: -1px;
	z-index: 9;
	display: flex;
	justify-content: center;
	align-items: center;
		
	.v-card {
		width: auto;
		border: $border;
		padding: 60px 40px;
	}
	h3 {
		@extend %titleFont;

		text-align: center;
    }

    p {
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.4px;
      	text-align: center;
    }
	.container-button{
        display: flex;
        justify-content: space-between;
    }

	.button > span > p{
		color: white !important
	}
}
	.v-stepper__content {
		padding: 0px !important
	}

	.v-input--selection-controls{
		margin-top: 0px !important;
	}
</style>
