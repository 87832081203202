<template>
	<div class="tw-w-full tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]">
		<HeaderSlot
		:title="project.name"
		:subTitle="project.description"
		:toBackButton="{path: project.isArchived? '/workspaces/archived' : '/workspaces'}"
		>
			<ButtonSlot
			@click="$refs.modal.showModale()"
			v-if="
				$hasRight('campaigns.createCampaignBtn') && 
					!project.isArchived
			"
			>
				Créer une nouvelle campagne
			</ButtonSlot>
		</HeaderSlot>

		<CampaignCreationModale ref="modal"/>

		<div class="tw-flex tw-h-[50px] tw-gap-[10px] tw-w-full">
			<div class="tw-flex tw-row tw-gap-[10px] tw-w-full">
				<v-text-field
				class="tw-max-w-[350px]"
				append-icon="mdi-magnify"
				clearable
				hide-details
				:label="$t('search_campaign')"
				@input="handleInput"
				v-model="fn"
				/>

				<v-select
				class="tw-max-w-[350px]"
				v-model="ft"
				:items="listType"
				label="Filtrer par type"
				hide-details
				:item-text="(value) => value.name"
				:item-value="(value) => value.type"
				clearable
				@input="handleInput"
				/>

				<v-select
				class="tw-max-w-[350px]"
				v-model="fs"
				:items="listStatus"
				:label="$t('filter_status')"
				multiple
				hide-details
				:item-text="(value) => value[1].label"
				:item-value="(value) => value[0]"
				:disabled="ft === ''"
				clearable
				@input="handleInput"
				>
					<template v-slot:selection="{ item }">
						<v-chip
						close
						close-icon="mdi-close"
						@click:close="SET_FILTERSTATUS(fs.filter(value => value !== item[0]))"
						small
						>
							<span>{{ item[1].label }}</span>
						</v-chip>
					</template>
				</v-select>

				<v-text-field
				class="tw-max-w-[350px]"
				append-icon="mdi-magnify"
				clearable
				hide-details
				label="Filtrer par référence"
				@input="handleInput"
				v-model="fr"
				/>
			</div>

			<div class="tw-flex tw-w-full tw-justify-end tw-mr-[16px] tw-items-end">
				<v-checkbox
				hide-details
				v-if="campaignInfos !== null && campaignInfos.archivedCampaignsCount !== 0"
				label="Afficher les campagnes archivées"
				v-model="fa"
				/>
			</div>
		</div>

		<div class="tw-grow tw-overflow-y-auto tw-flex tw-flex-col tw-pr-[5px] tw-gap-[10px]">
			<div
			v-if="campaignInfos !== null && campaignInfos.coringCampaignsCount !== 0 && $hasRight('users.isSuperAdmin')"
			class="tw-flex tw-flex-col tw-gap-[10px] tw-p-[10px] tw-justify-between tw-cursor-pointer tw-items-center tw-border-[#2C0703]"
			campaign-card
			@click="redirectMacro()"
			>
				<h2 class="majFL tw-whitespace-nowrap tw-text-ellipsis tw-overflow-x-hidden">
					{{ $store.state.user.current.job !== null ? 'Cartographie des campagnes de diagnostics Amiante / HAP / HCT ' : 'Cartographie des campagnes de diagnostics Amiante / HAP / HCT' }}
				</h2>

				<div>
					{{ $store.state.user.current.job !== null ? 'Visualiser les résultats des campagnes de diagnostics Amiante / HAP / HCT' : 'Visualiser les résultats des campagnes de diagnostics Amiante / HAP / HCT de votre réseau' }}
				</div>
			</div>

			<WaitingSlot
			class="tw-w-full tw-h-full"
			v-if="campaigns === false"
			/>

			<CampaignCard
			v-else
			v-for="campaign of displayCampaigns"
			class=""
			:campaign="campaign"
			@archiveCampaign="resetFilter"
			@refreshCampaigns="refreshCampaigns()"
			@refreshCampaignsDelete="refreshCampaignsDelete()"
			/>

			<v-pagination
			v-if="this.campaignInfos !== null"
			v-model="page"
			:length="getPages()"
			rounded="circle"
			/>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CampaignCard from "../../components/campaign/Campaign.card.vue";
import CampaignCreationModale from "../../components/campaign/CreateCampaign.modal.vue";
import {infoStatusAuscultation} from "../../utils/auscultation";
import {infoStatusCoringCA, infoStatusCoringCS} from "../../utils/coring";

export default {
	name: "Campaign",
	components: {
		CampaignCard,
		CampaignCreationModale
	},
	data(){
		return {
			listType: [],
			campaignInfos: null,
			limit: 8,
			offset: 0,
			page: 1,
			displayCampaigns: [],
			timeoutId: null,
			fn: null,
			fs: null,
			ft: null,
			fa: false,
			fr: null
		};
	},
	computed: {
		...mapGetters("project", [
			"project", "campaigns", "filterArchived", "getPageLoaded", "refreshNewCampaign", "campaignPage"
		]),
		...mapGetters("workspace", [
			"filterCampaign", "filterType", "filterReference"
		]),
		listStatus(){
			if(this.ft === "auscultation") return Object.entries(infoStatusAuscultation).slice(0, -1);
			else if(this.ft === "coring") return Object.entries(infoStatusCoringCA).slice(0, -1);
			else return [];
		}
	},
	watch: {
		listType(){
			if(this.listType.length === 1){
				this.ft = this.listType[0].type;
			}
		},
		async page(){
			if(this.project === false && this.page !== 1){
				this.setProject(this.$route.params.id);
			}
			this.SET_CAMPAIGN_PAGE(this.page);
			this.offset = (this.page - 1) * this.limit;
			
			if(undefined === this.getPageLoaded.find(e => e === this.page)){
				let sliceEnd;
				this.updateCampaigns({
					workspaceId: this.$route.params.id, limit: this.limit, offset: this.offset, query: this.fn, status: this.fs, campaignType: this.ft, archived: this.fa, reference: this.fr
				}).then(async e => {
					this.UPDATE_PAGELOADED(this.page);
					this.campaignInfos = await this.$api.workspaces.campaignsInfos(this.$route.params.id, this.fn, this.ft, this.fr, this.fs);
					if(this.fa === true){
						sliceEnd = this.limit + this.offset > this.campaignInfos.campaignsCount ? this.campaignInfos.campaignsCount : this.limit + this.offset;
					}
					else {
					 	sliceEnd = this.limit + this.offset > (this.campaignInfos.campaignsCount - this.campaignInfos.archivedCampaignsCount) ? (this.campaignInfos.campaignsCount - this.campaignInfos.archivedCampaignsCount) : this.limit + this.offset;
					}
					this.displayCampaigns = this.campaigns.slice(this.offset, sliceEnd);
				});
			}
			else {
				this.campaignInfos = await this.$api.workspaces.campaignsInfos(this.$route.params.id, this.fn, this.ft, this.fr, this.fs);
				this.displayCampaigns = this.campaigns.slice(this.offset, this.limit + this.offset);
			}

		},
		fn(){
			if(this.fn === null){
				this.resetFilter();
			}
		},
		fr(){
			if(this.fr === null){
				this.resetFilter();
			}
		},
		fs(){
			if(this.fs.length === 0 || this.fs === null){
				this.fs = null;
				this.resetFilter();
			}
		},
		fa(){
			this.CLEAR_CAMPAIGNS();
			this.page = 1;
			this.offset = 0;
			this.updateCampaigns({
				workspaceId: this.$route.params.id, limit: this.limit, offset: this.offset, query: this.fn, status: this.fs, campaignType: this.ft ?? null, archived: this.fa, reference: this.fr
			}).then(e => {
				this.UPDATE_PAGELOADED(this.page);
				let sliceEnd = 0;
				if(this.fa === true){
					sliceEnd = this.limit + this.offset > this.campaignInfos.campaignsCount ? this.campaignInfos.campaignsCount : this.limit + this.offset;
				}
				else {
					 sliceEnd = this.limit + this.offset > (this.campaignInfos.campaignsCount - this.campaignInfos.archivedCampaignsCount) ? (this.campaignInfos.campaignsCount - this.campaignInfos.archivedCampaignsCount) : this.limit + this.offset;
				}
				this.displayCampaigns = this.campaigns.slice(this.offset, sliceEnd);
			});
		},
		refreshNewCampaign(){
			if(this.refreshNewCampaign === true){
				this.resetFilter();
				this.SET_REFRESH_NEW_CAMPAIGN(false);
			}
		},
		campaignInfos(){
			if(this.campaignInfos !== null){
				this.listType = [];
				if(this.campaignInfos.coringCampaignsCount > 0){
					this.listType.push({name: "Carottage", type: "coring"});
				}
				if(this.campaignInfos.auscultationCampaignsCount > 0){

					this.listType.push({name: "Auscultation", type: "auscultation"});
				}
			}

			/*if(this.filterType !== false){
				this.ft = this.filterType;
			}*/
		},
		campaigns(){
			this.displayCampaigns = this.campaigns;
		}
	},
	methods: {
		...mapActions("project", [
			"initStore", "purgeStore", "updateCampaigns", "filterCampaignsByName", "setProject"
		]),
		...mapMutations("project", [
			"SET_FILTERSTATUS", "SET_FILTERTYPE", "SET_FILTERARCHIVED", "SET_CAMPAIGN_REFERENCE", "SET_PAGELOADED", "UPDATE_PAGELOADED", "CLEAR_CAMPAIGNS", "SET_REFRESH_NEW_CAMPAIGN", "SET_CAMPAIGN_PAGE"
		]),

		refreshCampaigns(){
			let sliceEnd = 0;
			if(this.fa === true){
				sliceEnd = this.limit + this.offset > this.campaignInfos.campaignsCount ? this.campaignInfos.campaignsCount : this.limit + this.offset;
			}
			else {
				sliceEnd = this.limit + this.offset > (this.campaignInfos.campaignsCount - this.campaignInfos.archivedCampaignsCount) ? (this.campaignInfos.campaignsCount - this.campaignInfos.archivedCampaignsCount) : this.limit + this.offset;
			}
			this.displayCampaigns = this.campaigns.slice(this.offset, sliceEnd);
		},
		refreshCampaignsDelete(){
			let sliceEnd;
			this.updateCampaigns({
				workspaceId: this.$route.params.id, limit: this.limit, offset: this.offset, query: this.fn, status: this.fs, campaignType: this.ft, archived: this.fa, reference: this.fr
			}).then(async e => {
				this.UPDATE_PAGELOADED(this.page);
				this.campaignInfos = await this.$api.workspaces.campaignsInfos(this.$route.params.id, this.fn, this.ft, this.fr, this.fs);
				if(this.fa === true){
					sliceEnd = this.limit + this.offset > this.campaignInfos.campaignsCount ? this.campaignInfos.campaignsCount : this.limit + this.offset;
				}
				else {
					 	sliceEnd = this.limit + this.offset > (this.campaignInfos.campaignsCount - this.campaignInfos.archivedCampaignsCount) ? (this.campaignInfos.campaignsCount - this.campaignInfos.archivedCampaignsCount) : this.limit + this.offset;
				}
				this.displayCampaigns = this.campaigns.slice(this.offset, sliceEnd);
			});
		},
		getPages(){
			if(this.fa === true){
				return Math.ceil(this.campaignInfos.campaignsCount / this.limit);
			}
			else {
				return Math.ceil((this.campaignInfos.campaignsCount - this.campaignInfos.archivedCampaignsCount) / this.limit);
			}
		},
		handleInput(){
			clearTimeout(this.timeoutId);

			// Définissez un nouveau délai pour déclencher l'action
			if(this.fn !== null || this.fs !== null || this.ft !== null || this.fr !== null){
				this.timeoutId = setTimeout(() => {

					this.page = 1;
					this.offset = 0;
					// Action à effectuer après 2 secondes
					this.filterCampaignsByName({
						workspaceId: this.$route.params.id, limit: this.limit, offset: this.offset, query: this.fn, status: this.fs, campaignType: this.ft,  archived: this.fa, reference: this.fr
					}).then(async() => {
						this.displayCampaigns = this.campaigns;
						this.campaignInfos = await this.$api.workspaces.campaignsInfos(this.$route.params.id, this.fn, this.ft, this.fr, this.fs);
						this.SET_PAGELOADED();
					});
				}, 1000);
			}
		},
		async resetFilter(){
			if(this.campaigns.length !== 0){
				this.CLEAR_CAMPAIGNS();
				this.campaignInfos = await this.$api.workspaces.campaignsInfos(this.$route.params.id, this.fn, this.ft, this.fr, this.fs);
				await this.updateCampaigns({
					workspaceId: this.$route.params.id, limit: this.limit, offset: this.offset, archived: this.fa, reference: this.fr
				});
			}
			else {
				await this.initStore({workspaceId: this.$route.params.id, limit: this.limit, offset: this.offset});
			}
			if(this.campaignInfos === null){
				this.campaignInfos = await this.$api.workspaces.campaignsInfos(this.$route.params.id, this.fn, this.ft, this.fr, this.fs);
			}
			this.displayCampaigns = this.campaigns;
			if(this.campaignInfos.campaignsCount !== 0){
				this.SET_PAGELOADED();
			}
		},
		redirectMacro(){
			this.$router.push({
				name: "coring-macro-project-map-id",
				params: {id: this.project.id}
			});
		}
	},
	async mounted(){
		if(this.filterCampaign !== false){
			this.fn = this.filterCampaign;
		}
		if(this.filterReference !== false){
			this.fr = this.filterReference;
		}
		if(this.campaignPage !== false){
			this.page = this.campaignPage;
		}
		if(this.campaignInfos === null){
			this.campaignInfos = await this.$api.workspaces.campaignsInfos(this.$route.params.id, this.fn, this.ft, this.fr, this.fs);
		}
		if(this.page === 1){
			await this.initStore({
				workspaceId: this.$route.params.id, limit: this.limit, offset: this.offset, query: this.fn, reference: this.fr
			});
			
			this.displayCampaigns = this.campaigns;
			if(this.campaignInfos.campaignsCount !== 0){
				this.SET_PAGELOADED();
			}
		}
		
	}
};
</script>

<style lang="scss" scoped>

</style>
