<template>
	<div class="auscultation_map__filter tw-select-none">
		<v-expansion-panels v-model="isOpen">
			<v-expansion-panel>
				<v-expansion-panel-header class="pt-0 pb-0">
					<h3>Liste des filtres</h3>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<div class="tw-flex tw-gap-[10px] tw-flex-col">
						<v-select
						v-model="roadFilterValue"
						:items="roadFilters"
						multiple
						outlined
						label="Nom de la voie"
						single-line
						hide-details
						clearable
						dense
						@change="handleSelect('road', roadFilterValue)"
						/>

						<v-select
						v-model="directionFilterValue"
						:items="directionFilters"
						multiple
						outlined
						label="Sens"
						single-line
						hide-details
						clearable
						dense
						@change="handleSelect('direction', directionFilterValue)"
						/>

						<v-select
						v-model="laneFilterValue"
						:items="laneFilters"
						multiple
						outlined
						label="Localisation / Voie"
						single-line
						hide-details
						clearable
						dense
						@change="handleSelect('lane', laneFilterValue)"
						/>

						<v-select
						v-model="landmarkFilterValue"
						:items="landmarkFilters"
						multiple
						outlined
						label="PR"
						single-line
						hide-details
						clearable
						dense
						@change="handleSelect('landmark', landmarkFilterValue)"
						/>

						<v-select
						v-model="lateralPositionFilterValue"
						:items="lateralPositionFilters"
						multiple
						outlined
						label="Latéralisation"
						single-line
						hide-details
						clearable
						dense
						@change="handleSelect('lateralPosition', lateralPositionFilterValue)"
						/>

						<v-select
						v-model="diameterFilterValue"
						:items="diameterFilters"
						multiple
						outlined
						label="Diamètre"
						single-line
						hide-details
						clearable
						dense
						@change="handleSelect('diameter', diameterFilterValue)"
						/>

						<v-select
						v-if="!$hasRight('users.isCustomer')"
						v-model="businessFilterValue"
						:items="businessFilters"
						multiple
						outlined
						label="Carottes"
						single-line
						hide-details
						clearable
						dense
						@change="handleSelect('business', businessFilterValue)"
						menu-props="auto"
						style="max-height: 100px; overflow-y: auto;"
						/>

						<ButtonSlot
						@click="resetFilters()"
						>
							Réinitialiser les filtres
						</ButtonSlot>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
	name: "CoringMapFilter",
	props: {
		isMacroProject: {
			type: Boolean,
			required: true
		}
	},
	data(){
		return {
			items: [],
			isOpen: 0,
			laneFilterValue: null,
			laneFilters: [],
			diameterFilterValue: null,
			diameterFilters: [],
			lateralPositionFilterValue: null,
			lateralPositionFilters: [],
			landmarkFilterValue: null,
			landmarkFilters: [],
			roadFilterValue: null,
			roadFilters: [],
			directionFilterValue: null,
			directionFilters: [],
			businessFilterValue: null,
			businessFilters: [],
		};
	},
	computed: {
		...mapGetters({
			coresCoring: "coring/cores",
			filterByValuesCoring: "coring/filterByValues",
			coresMacroCoring: "coringMacro/cores",
		}),
		cores(){
			return this.isMacroProject ? this.coresMacroCoring : this.coresCoring;
		},
	},
	watch: {
		cores(){
			if(this.cores !== false){
				this.setFilterValues();
			}
		}
	},
	methods: {
		...mapMutations("coring", [
			"REMOVE_FILTER_BY_VALUES", "SET_FILTER_BY_VALUES", "REMOVE_ALL_FILTER_BY_VALUES"
		]),
		handleSelect(type, value){
			if(value.length !== 0){
				this.SET_FILTER_BY_VALUES({type: type, value: value});
			}
			else {
				let index = this.filterByValuesCoring.findIndex((key, value) => key === type);
				this.REMOVE_FILTER_BY_VALUES(index);
			}
		},
		setFilterValues(){
			this.cores.forEach(core => {
				if(undefined === this.laneFilters.find(e => e === core.lane)){
					if(core.lane !== null){
						this.laneFilters.push(core.lane);
					}
				}
				if(undefined === this.diameterFilters.find(e => e === core.diameter)){
					this.diameterFilters.push(core.diameter);
				}
				if(undefined === this.lateralPositionFilters.find(e => e === core.lateralPosition)){
					this.lateralPositionFilters.push(core.lateralPosition);
				}
				if(undefined === this.landmarkFilters.find(e => e === core.landmark)){
					this.landmarkFilters.push(core.landmark);
				}
				if(core.road !== undefined && undefined === this.roadFilters.find(e => e === core.road.name)){
					this.roadFilters.push(core.road.name);
				}
				if(undefined === this.directionFilters.find(e => e === core.direction)){
					this.directionFilters.push(core.direction);
				}
				if(core.displayNamePrefix !== null && core.displayNamePrefix !== undefined && core.displayName !== null){
					if(this.$route.name === "coring-macro-campaigns-map-id" || this.$route.name === "coring-macro-project-map-id" || this.$route.name === "coring-macro-campaigns-map"){
						this.businessFilters.push(core.displayNamePrefix[0].name + core.displayName);
					}
					else {
						this.businessFilters.push(core.displayNamePrefix.name + core.displayName);
					}
				}
				else {
					this.businessFilters.push(core.businessId);
				}
			});

			this.laneFilters.sort((a, b) => a.localeCompare(b));
			this.diameterFilters.sort((a, b) => a - b);
			this.lateralPositionFilters.sort((a, b) => a.localeCompare(b));
			this.landmarkFilters.sort((a, b) => a - b);
			this.roadFilters.sort((a, b) => a.localeCompare(b));
			this.directionFilters.sort((a, b) => a - b);
			this.businessFilters.sort((a, b) => a.localeCompare(b));
		},
		resetFilters(){
			this.laneFilterValue = null;
			this.diameterFilterValue = null;
			this.lateralPositionFilterValue = null;
			this.landmarkFilterValue = null;
			this.roadFilterValue = null;
			this.directionFilterValue = null;
			this.REMOVE_ALL_FILTER_BY_VALUES();
		}
		
	}
};
</script>

<style lang="scss" scoped>
.auscultation_map__filter {
	min-width: 350px;
  font-family: Roboto, sans-serif;
  .panel-header_overtext {
    padding: 16px 18px 10px 18px;
    font-size: 20px;
  }
  .v-expansion-panel-header {
    font-size: 15px;
  }
  .v-expansion-panel-content {
    &__wrap {
      max-height: 74vh;
      overflow-y: auto;
    }
  }
  .v-treeview-node {
    &__root {
      min-height: 30px;
    }
    &__children {
      min-height: 30px;
    }
  }
  .reset_button_container {
    display: flex;
    justify-content: center;
  }

  .miniCheckbox .mdi-checkbox-blank-outline::before,
  .miniCheckbox .mdi-checkbox-marked::before {
    transform: scale(0.8) !important;
  }

  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: #ccc;
  }

  ::-webkit-scrollbar-thumb {
    background: #290703ee;
    -webkit-border-radius: 1ex;
    border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }
  
  .background {
    background-color: white;
  }
}
</style>
