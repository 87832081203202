// @ts-nocheck
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_patterns from "@amcharts/amcharts4/themes/patterns";
import CHART_DIRECTION_CONSTANT from "./dashboard/chartdirections.const";

// Config for daisy chaining, see https://www.amcharts.com/docs/v4/concepts/performance/#Daisy_chaining_multiple_charts
am4core.options.queue = true;
am4core.options.deferredDelay = 500;

am4core.options.autoDispose = true;
am4core.options.animationsEnabled = false;
am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_patterns);

const baseColors = [
	"#F28583",
	"#8DBCEA",
	"#FDA384",
	"#9FB492",
	"#F3EA89",
	"#9C97D2",
	"#8BCAB2",
	"#F7A3B2",
	"#90B7DC",
	"#A8DC98",
	"#E6EE92",
	"#C19DC7",
	"#88BDAB",
	"#F5947E",
	"#BDCAB3",
	"#CBB0D7",
	"#A1DACB",
	"#FBDFE4",
	"#F9F4AD",
	"#8DBCEA"
];


// Anciennes couleurs (orangées)
// const colors = [
//   "#BE5900",
//   "#FF7800",
//   "#FF8B25",
//   "#FFA14E",
//   "#FFB725",
//   "#F79909",
//   "#D58100"
// ];

/*const reRenderSvg = (ref, type = "before") => {
  var chart = document.querySelector(`#${ref}`);
  var box = chart.childNodes[0];
  var svg = chart.childNodes[0].childNodes[0];
  if (svg) {
    if ("after" === type) {
      // remove the attributes after generating canvas
      svg.removeAttribute("width");
      svg.removeAttribute("height");
      svg.removeAttribute("fontSize");
    } else {
      svg.querySelectorAll("tspan").forEach(tspan => {
        tspan.style.fontSize = 12;
        tspan.style.fontFamily = "Roboto";
      });

      // set width and height according to parent container
      svg.setAttribute("width", box.clientWidth);
      svg.setAttribute("height", box.clientHeight);
      svg.setAttribute("fontSize", 24);
    }
  }
};*/

const amchartUtils = {
	/**
   * @description create a Pie chart
   * @param {string} ref - the HTML element using its id
   * @param {object} data - the data used for the chart
   * @param {string} title - the title of the chart
   * @returns void
   */
	createPieSimpleChart(ref, data, title){
		return am4core.createDeferred(() => {
			// CHART
			const ch = am4core.create(ref, am4charts.PieChart);
			ch.language.locale["_thousandSeparator"] = " ";

			this.setNoDataIndicator(ch, this);

			ch.paddingTop = 15;
			ch.data = data;
			ch.logo.disabled = true;
			ch.radius = am4core.percent(66);
			// LEGEND
			ch.legend = new am4charts.Legend();
			ch.legend.position = "left";
			ch.legend.fontSize = 12;
			ch.legend.markers.template.width = 15;
			ch.legend.markers.template.height = 15;
			ch.legend.labels.template.truncate = false;
			ch.legend.numberFormatter.numberFormat = "#,###.";
			ch.legend.valueLabels.template.text = "";
			ch.legend.valign = "middle";
			ch.legend.paddingLeft = 50;

			// TITLE
			const t = ch.titles.create();
			t.text = title;
			t.fontSize = 18;

			// SERIE
			const sr = ch.series.push(new am4charts.PieSeries());
			sr.dataFields.value = "value";
			sr.dataFields.category = "name";
			//sr.tooltip.background.propertyFields.fill = "color";
			sr.slices.template.propertyFields.fill = "color";
			sr.events.on("datavalidated", ev => {
				/**
         * @description hide data and legend for each slice with value equal to zero
         * @returns void
         */
				ev.target.slices.each(slice => {
					// if slice value is equal to zero
					if(0 === slice.dataItem.values.value.percent){
						// hide slice
						slice.dataItem.hide();
						// and its legend
						slice.dataItem.legendDataItem.hide();
						slice.dataItem.legendDataItem.height = 0;
					}
				});
			});

			// LABEL
			const lbl = sr.labels.template;
			lbl.maxWidth = 60;
			lbl.wrap = true;
			lbl.text = "{value.percent.formatNumber('#.0')}% ({value.value})";

			return ch;
		});
	},

	createLineSimpleChart(	
		ref,
		data,
		colors,
		serieKeys,
		title,
		direction,
		axisLabel
	){
		// CHART
		const ch = am4core.create(ref, am4charts.XYChart);
		ch.logo.disabled = true;
		ch.data = data;
		ch.language.locale["_thousandSeparator"] = " ";
		ch.maskBullets = false;
		this.setTitle(ch, title);

		this.setNoDataIndicator(ch, this);

		ch.legend = new am4charts.Legend();
		ch.legend.fontSize = 12;
		ch.legend.marginTop = 20;

		// Y AXIS
		const xax = ch.xAxes.push(new am4charts.CategoryAxis());
		xax.dataFields.category = "type";
		xax.title.text = axisLabel;
		xax.renderer.grid.template.location = 0;
		xax.numberFormatter.numberFormat = "#,###.##";

		// X AXIS
		const yax = ch.yAxes.push(new am4charts.ValueAxis());
		yax.title.text = "";
		//yax.min = 0;
		yax.numberFormatter.numberFormat = "#,###.##";
		let i = 0;
		// Create all series
		serieKeys.forEach(key => {
			const sr = ch.series.push(new am4charts.LineSeries());
			sr.dataFields.valueY = key;
			sr.dataFields.categoryX = "type";
			sr.name = key;
			sr.strokeWidth = 2;
			sr.tooltipText = key + " : [bold]{valueY}[/]";
			if(colors !== null && Object.keys(colors).length !== 0){
				sr.stroke = am4core.color(colors[key]); 
			}
			else {
				sr.stroke = am4core.color(baseColors[i]);
				i++;
			} 
		});

		return ch;
	},

	setTitle(ch, title){
		// TITLE
		const t = ch.titles.create();
		t.text = title;
		t.fontSize = 18;
		t.marginBottom = 30;

		// CURSOR
		ch.cursor = new am4charts.XYCursor();
	},
	setNoDataIndicator(ch, that){
		ch.events.on("beforedatavalidated", function(ev){
			// check if there's data
			if(ev.target.data.length == 0){
				that.showNoDataIndicator(ch);
			}
			else if(ch.noDataIndicator){
				that.hideNoDataIndicator(ch);
			}
		});
	},
	showNoDataIndicator(ch){
		if(ch.noDataIndicator){
			ch.noDataIndicator.show();
		}
		else {
			ch.noDataIndicator = ch.tooltipContainer.createChild(am4core.Container);
			ch.noDataIndicator.background.fill = am4core.color("#fff");
			ch.noDataIndicator.background.fillOpacity = 0.8;
			ch.noDataIndicator.width = am4core.percent(100);
			ch.noDataIndicator.height = am4core.percent(100);

			var indicatorLabel = ch.noDataIndicator.createChild(am4core.Label);
			indicatorLabel.text = "Pas de données...";
			indicatorLabel.align = "center";
			indicatorLabel.valign = "middle";
			indicatorLabel.fontSize = 20;
		}
	},
	hideNoDataIndicator(ch){
		ch.noDataIndicator.hide();
	},
	async createColumnNBar(ref, data){
		const ch = am4core.create(ref, am4charts.XYChart);
		ch.language.locale["_thousandSeparator"] = " ";
		ch.data = data;
		ch.logo.disabled = true;
		ch.interpolationDuration = 0;
		ch.startAlpha = 0;
		ch.inversed = false;
		return ch;
	},
	createVerticalColumnNBarStackedChart(
		ch, 
		data, 
		colors,
		serieKeys,
		axisLabel,
		categoryLabelRotation = 0,
		isStat
	){
		// X AXIS
		const xax = ch.xAxes.push(new am4charts.CategoryAxis());
		xax.dataFields.category = "type";
		xax.title.text = "";
		xax.numberFormatter.numberFormat = "#,###.";
		xax.renderer.labels.template.rotation = categoryLabelRotation;
		xax.renderer.minGridDistance = 15;

		// X LABEL
		const lb = xax.renderer.labels.template;
		lb.truncate = false;
		lb.wrap = true;
		lb.tooltipText = "{type}";

		/**
     * @description ajust label x position on render
     * @returns void
     */
		xax.events.on("sizechanged", function(ev){
			var axis = ev.target;
			var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
			axis.renderer.labels.template.maxWidth = cellWidth;
		});

		// Y AXIS
		const yax = ch.yAxes.push(new am4charts.ValueAxis());

		yax.min = 0;
		yax.title.text = axisLabel;
		//yax.renderer.grid.template.location = 0;
		//yax.renderer.minGridDistance = 20;
		yax.numberFormatter.numberFormat = "#,###.";

		// SERIES
		let i = 0;

		// Creae all series
		serieKeys.forEach(key => {
			const sr = ch.series.push(new am4charts.ColumnSeries());
			sr.dataFields.valueY = key;
			sr.dataFields.categoryX = "type";
			// sr.columns.template.fill = am4core.color(data[index].color);
			// sr.columns.template.stroke = am4core.color(data[index].color);
			sr.name = key;
			if(isStat){
				sr.tooltipText = "{name}: [bold]{valueY}[/]";
			}
			else {
				sr.tooltipText = "{type}: [bold]{valueY}[/]";
			}
			sr.strokeWidth = 0;
			sr.stacked = true;
			if(Object.prototype.hasOwnProperty.call(colors, key)){
				sr.columns.template.fill = colors[key];
			}
			else {
				if(colors.length !== 0){
					sr.tooltip.background.fill = am4core.color(colors[i]);
					let pattern = new am4core.RectPattern();
					pattern.backgroundFill = am4core.color(colors[i]);
					//pattern.backgroundOpacity = 0.2;
					pattern.stroke = am4core.color(colors[i]);
					pattern.strokeWidth = 2;
					sr.columns.template.fill = pattern;
					i++;
				}
				else {
					this.setInfos(sr, i);
					i++;
				}
				
			}
		});
		//reRenderSvg(ref);
		return ch;
	},
	createHorizontalColumnNBarStackedChart(ch, data, colors, serieKeys, axisLabel){
		// Y AXIS
		const yax = ch.yAxes.push(new am4charts.CategoryAxis());
		yax.dataFields.category = "type";
		yax.title.text = "";
		yax.renderer.grid.template.location = 0;
		yax.renderer.minGridDistance = 15;
		yax.numberFormatter.numberFormat = "#,###.";

		// X AXIS
		const xax = ch.xAxes.push(new am4charts.ValueAxis());
		xax.title.text = axisLabel;
		xax.numberFormatter.numberFormat = "#,###.";
    
		// SERIES
		let i = 0;
   
		// Creae all series
		serieKeys.forEach(key => {
			const sr = ch.series.push(new am4charts.ColumnSeries());
			sr.dataFields.valueX = key;
			sr.dataFields.categoryY = "type";
			// sr.columns.template.fill = am4core.color(data[index].color);
			// sr.columns.template.stroke = am4core.color(data[index].color);
			sr.name = key;
			sr.tooltipText = "{name}: [bold]{valueX}[/]";
			sr.strokeWidth = 0;
			//sr.tooltipText = "{name}: [bold]{value}[/]";
			sr.stacked = true;
			if(Object.prototype.hasOwnProperty.call(colors, key)){
				sr.columns.template.fill = colors[key];
			}
			else {
				this.setInfos(sr, i);
				++i;
			}
		});

		//reRenderSvg(ref);
		return ch;
	},
	async createColumnNBarStackedChart(
		ref,
		data,
		colors,
		serieKeys,
		title,
		direction,
		axisLabel,
		categoryLabelRotation = 0,
		isStat = false
	){
		// CHART
		const ch = await this.createColumnNBar(ref, data);
		ch.language.locale["_thousandSeparator"] = " ";

		this.setNoDataIndicator(ch, this);

		this.setTitle(ch, title);

		// LEGEND
		ch.legend = new am4charts.Legend();
		ch.legend.fontSize = 12;
		ch.legend.marginTop = 20;

		if(direction == CHART_DIRECTION_CONSTANT.Vertical){
			return this.createVerticalColumnNBarStackedChart(
				ch,
				data,
				colors,
				serieKeys,
				axisLabel,
				categoryLabelRotation,
				isStat
			);
		}
		else {
			return this.createHorizontalColumnNBarStackedChart(
				ch,
				data,
				colors,
				serieKeys,
				axisLabel
			);
		}
	},
	createVerticalColumnNBarSimpleChart(
		ch, 
		data, 
		colors,
		serieKeys,
		axisLabel, 
		showXCategoryLabel = false,
		categoryLabelRotation = 0
	){

		// X AXIS
		const categoryAxis = ch.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "type";
		categoryAxis.title.text = "";
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 15;
		categoryAxis.numberFormatter.numberFormat = "#,###.";
		categoryAxis.renderer.labels.template.valign = "top";
		categoryAxis.renderer.labels.template.rotation = categoryLabelRotation;
		categoryAxis.renderer.labels.template.disabled = !showXCategoryLabel;
    
		// Y AXIS
		const yax = ch.yAxes.push(new am4charts.ValueAxis());
		yax.min = 0;
		yax.title.text = axisLabel;
		yax.numberFormatter.numberFormat = "#,###.";

		// X LABEL
		const lb = categoryAxis.renderer.labels.template;
		lb.truncate = false;
		lb.maxWidth = 200;
		lb.wrap = true;
		lb.tooltipText = "{category}";

		/**
     * @description ajust label x position on render
     * @returns void
     */
		categoryAxis.events.on("sizechanged", ev => {
			var axis = ev.target;
			var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
			axis.renderer.labels.template.maxWidth = cellWidth;
		});
    
		// Creae all series
		serieKeys.forEach(key => {
			const sr = ch.series.push(new am4charts.ColumnSeries());
			sr.dataFields.valueY = key;
			sr.dataFields.categoryX = "type";
			sr.name = key;
			sr.strokeWidth = 0;
			sr.tooltipText = "{type}: [bold]{valueY.formatNumber('#.##')}[/]";

			sr.columns.template.width = am4core.percent(95);

			sr.columns.template.adapter.add("fill", function(_, target){
				let colorIndex = key;
				if(serieKeys.length == 1){
					colorIndex = target.dataItem.categoryX;
				}
				if(Object.prototype.hasOwnProperty.call(colors, colorIndex)){
					return colors[colorIndex];
				}
				else {
					if(colors !== null){
						sr.tooltip.background.fill = am4core.color(colors);
						return colors;
					} 
					return "#2B0803";
				}
			});
		});
		return ch;
	},
	createHorizontalColumnNBarSimpleChart(
		ch, 
		data, 
		colors, 
		serieKeys, 
		axisLabel
	){
		// Y AXIS
		const yax = ch.yAxes.push(new am4charts.CategoryAxis());
		yax.dataFields.category = "type";
		yax.title.text = "";
		yax.renderer.grid.template.location = 0;
		yax.renderer.minGridDistance = 15;
		yax.numberFormatter.numberFormat = "#,###.";

		// X AXIS
		const xax = ch.xAxes.push(new am4charts.ValueAxis());
		xax.title.text = axisLabel;
		xax.numberFormatter.numberFormat = "#,###.";

		// Creae all series
		serieKeys.forEach(key => {
			const sr = ch.series.push(new am4charts.ColumnSeries());
			sr.dataFields.valueX = key;
			sr.dataFields.categoryY = "type";
			sr.name = key;
			sr.strokeWidth = 0;
			sr.tooltipText = "{type}: [bold]{valueX}[/]";
			sr.columns.template.adapter.add("fill", function(_, target){
				let colorIndex = key;
				if(serieKeys.length == 1){
					colorIndex = target.dataItem.categoryY;
				}
				if(Object.prototype.hasOwnProperty.call(colors, colorIndex)){
					return colors[colorIndex];
				}
				else {
					return "#210502";
				}
			});
		});
		return ch;
	},
	async createColumnNBarSimpleChart(
		ref,
		data,
		colors,
		serieKeys,
		title,
		direction,
		axisLabel,
		withLegend = false,
		withXCategoryLabel = false,
		categoryLabelRotation = 0
	){
		// CHART
		const ch = await this.createColumnNBar(ref, data);
		ch.language.locale["_thousandSeparator"] = " ";
		ch.inversed = false;
		ch.reverseOrder = false;
		ch.maskBullets = false;
		this.setTitle(ch, title);

		this.setNoDataIndicator(ch, this);

		// LEGEND
		if(withLegend){
			ch.legend = new am4charts.Legend();
			ch.legend.fontSize = 12;
			ch.legend.marginTop = 20;
		}

		if(direction == CHART_DIRECTION_CONSTANT.Horizontal){
			return this.createHorizontalColumnNBarSimpleChart(ch, data, colors, serieKeys, axisLabel);
		}
		else {
			return this.createVerticalColumnNBarSimpleChart(ch, data, colors, serieKeys, axisLabel, withXCategoryLabel, categoryLabelRotation);
		}
	},
	setInfos(sr, i){
		let baseColorCount = baseColors.length;
		let colorIndex = i % baseColorCount;

		// Déterminer quel cycle on est en train d'afficher (couleur pleine ou pattern)
		let cycle = Math.floor(i / baseColorCount); // Divise l'index par le nombre de couleurs pour déterminer le cycle

		let pattern = null;
		sr.strokeWidth = 0;

		// Cycle 0 : couleurs pleines, Cycle 1 : pattern 1, Cycle 2 : pattern 2, etc.
		if(cycle === 0){
			// Appliquer la couleur pleine sans pattern
			sr.columns.template.fill = am4core.color(baseColors[colorIndex]);
			sr.columns.template.stroke = am4core.color(baseColors[colorIndex]);
		}
		else if(cycle === 1){
			// Appliquer le premier pattern avec la couleur
			pattern = new am4core.LinePattern();
			pattern.rotation = 135;
		}
		else if(cycle === 2){
			// Appliquer le deuxième pattern avec la couleur
			pattern = new am4core.LinePattern();
			pattern.rotation = 225;
		}
		else if(cycle === 3){
			// Appliquer un troisième pattern si nécessaire
			pattern = new am4core.RectPattern();
		}

		if(pattern !== null){
			pattern.stroke = am4core.color(baseColors[colorIndex]);
			pattern.strokeWidth = 2;
			sr.columns.template.fill = pattern;
		}

		sr.tooltip.background.fill = am4core.color(baseColors[colorIndex]);
		sr.stacked = true;
	},
	disposeAll(){
		am4core.disposeAllCharts();
	}
};
export default amchartUtils;
