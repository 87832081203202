<template>
	<div
	class="draggable"
	:style="{ top: `${position.top}px`, left: `${position.left}px`, width: `${size.width}px`, height: `${size.height}px`, position: 'absolute' }"
	@mousedown="onMouseDown"
	id="dragg"
	>
		<Resize
		@resize="onResize"
		class="resize-box tw-p-[10px] tw-h-[380px] tw-w-[500px]"
		style="background:white; border: 1px solid black; border-radius: 4px;"
		>
			<ButtonSlot
			_icon="mdi-close"
			class="tw-left-[10px] tw-mb-[10px] tw-absolute"
			@click="close()"
			/>
			<div class="tw-h-full tw-overflow-y-auto tw-items-center tw-flex tw-flex-col tw-gap-[10px]">
				<h2>Filtrer les carotes</h2>
				<div class="tw-flex tw-flex-col tw-gap-[10px]">
					<!-- Filtres -->
					<v-select
					outlined
					dense
					v-model="filters['lane']"
					multiple
					:items="filterLanes"
					hide-details
					clearable
					label="Filtrer le type de voie"
					/>

					<v-select
					outlined
					dense
					v-model="filters['direction']"
					multiple
					:items="filterDirection"
					hide-details
					clearable
					label="Filtrer le sens"
					/>

					<v-select
					outlined
					dense
					v-model="filters['lateralPosition']"
					multiple
					:items="filterLateralPosition"
					hide-details
					clearable
					label="Filtrer l'axe"
					/>
				</div>
			</div>
			<div class="vue-resizer-handle vue-resizer-handle-top-left"/>
			<div class="vue-resizer-handle vue-resizer-handle-top-right"/>
			<div class="vue-resizer-handle vue-resizer-handle-bottom-left"/>
			<div class="vue-resizer-handle vue-resizer-handle-bottom-right"/>
		</Resize>
	</div>
</template>
  
<script>
import {Resize} from "vue-resizer";
  
export default {
	name: "CoringFilterPrestations",
	components: {
	  Resize
	},
	props: {
		cores: {
			type: Array,
			required: true
		}
	},
	data(){
	  return {
			position: {top: 100, left: 100},
			size: {width: 300, height: 600},
			isDragging: false,
			dragStart: {top: 0, left: 0},
			filters: {lane: []},
			filterLanes: [],
			filterDirection: [],
			filterLateralPosition: []
	  };
	},
	watch: {
	  filters: {
			handler(newVal){
		  this.$emit("filterUpdated", {...newVal});
			},
			deep: true,
	  },
	  cores: {
			handler(newVal){
				newVal.forEach(core => {
					if(this.filterLanes.includes(core.lane) === false && core.lane !== null){
		  				this.filterLanes.push(core.lane);
					}
					if(this.filterDirection.includes(core.direction) === false && core.direction !== null){
		  				this.filterDirection.push(core.direction);
					}
					if(this.filterLateralPosition.includes(core.lateralPosition) === false && core.lateralPosition !== null){
		  				this.filterLateralPosition.push(core.lateralPosition);
					}
				});
	 		},
			deep: true
		}
	},
	methods: {
	  onResize(event){
			this.size.width = event.width;
			this.size.height = event.height;
			console.log(`Resized to ${event.width}x${event.height}`);
	  },
	  onMouseDown(event){
			if(event.target.classList.contains("resize-box")){
		  		return;
			}
			this.isDragging = true;
			this.dragStart = {
				top: event.clientY - this.position.top,
				left: event.clientX - this.position.left
			};
			document.addEventListener("mousemove", this.onMouseMove);
			document.addEventListener("mouseup", this.onMouseUp);
	  },
	  onMouseMove(event){
			if(this.isDragging){
		  this.position.top = event.clientY - this.dragStart.top;
		  this.position.left = event.clientX - this.dragStart.left;
			}
	  },
	  onMouseUp(){
			this.isDragging = false;
			document.removeEventListener("mousemove", this.onMouseMove);
			document.removeEventListener("mouseup", this.onMouseUp);
	  },
	  close(){
			this.$emit("close");
	  },
	}
};
</script>
  

<style scoped>
.draggable-container {
  cursor: move;
  display: inline-block; /* S'ajuste automatiquement à la taille de son contenu */
  border: 1px solid #3f51b5;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.resize-box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.draggable-content {
  width: 100%;
  height: 100%;
}

.vue-resizer-handle {
  background: #3f51b5;
  position: absolute;
  z-index: 1;
}

.vue-resizer-handle-top {
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  cursor: n-resize;
}

.vue-resizer-handle-right {
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  cursor: e-resize;
}

.vue-resizer-handle-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  cursor: s-resize;
}

.vue-resizer-handle-left {
  top: 0;
  left: 0;
  bottom: 0;
  width: 10px;
  cursor: w-resize;
}
</style>
